import React, {useEffect, useState} from 'react';
import Loader from "./Loader";

const ContactUsModal = ({ isOpen, onClose }) => {
    // Animation state
    const [shouldRender, setShouldRender] = useState(isOpen);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [status, setStatus] = useState({
        submitted: false,
        submitting: false,
        error: false,
        message: ''
    });

    useEffect(() => {
        if (isOpen) setShouldRender(true);
    }, [isOpen]);

    const onAnimationEnd = () => {
        if (!isOpen) setShouldRender(false);
    };

    if (!shouldRender) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setStatus({ ...status, submitting: true });
        // Check if any field is empty
        if (!formData.name || !formData.email || !formData.message) {
            alert('Please fill in all the fields.');
            return;
        }

        // Here, you would implement the API call to Zapier webhook
        // which then triggers the email action.
        try {
            const response = await fetch(`${process.env.REACT_APP_ZAPIER_WEBHOOK_URL}`, {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response) {
                setStatus({
                    submitted: true,
                    submitting: false,
                    error: false,
                    message: 'Thank you! Your message has been sent. We will get back to you soon.'
                });
                setFormData({ name: '', email: '', message: '' }); // Clear the form
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setStatus({
                submitted: false,
                submitting: false,
                error: true,
                message: 'There was a problem submitting your message. Please try again.'
            });
        }
    };

    if (!isOpen) return null;

    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxSizing: 'border-box',
    };

    const buttonStyle = {
        backgroundColor: '#000',
        color: '#FFF',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    };

    if (!isOpen) return null;

    return (
        <div className="modal" style={{animation: `${isOpen ? 'fadeIn' : 'fadeOut'} 0.5s`}}
             onAnimationEnd={onAnimationEnd}>
            <div className="modal-content">
                <button className="absolute top-0 right-3 text-3xl text-gray-600 p-3 rounded-t-none" onClick={onClose}>&times;</button>
                <h1 className="text-4xl font-bold text-gray-900 my-4">Contact Us</h1>
                <p className="text-lg text-gray-700 mb-4">We're here to help and answer any question you might have. We
                    look forward to hearing from you.</p>
                {status.submitted ? (
                    <div
                        className="confirmation-message flex flex-col items-center justify-center p-4 rounded-lg bg-green-100">
                        <i className="fas fa-check-circle text-green-500 text-5xl"></i>
                        <p className="text-green-800 font-semibold mt-3">{status.message}</p>
                    </div>
                ) : (
                    <form onSubmit={handleFormSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            style={inputStyle}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            style={inputStyle}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Type your message..."
                            style={{...inputStyle, height: '150px'}}
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        { status.submitting ? <Loader /> : (<button type="submit" style={buttonStyle}>Submit</button>) }


                    </form>
                )}
            </div>
        </div>
    );
};


export default ContactUsModal;
