export function scrollToNextSection() {
    // Calculate the next section's starting point
    const nextSectionY = window.pageYOffset + window.innerHeight;
    // Scroll to the next section smoothly
    window.scrollTo({
        top: nextSectionY,
        behavior: 'smooth'
    });
}

export function getApiBaseUrl() {
    return `${process.env.REACT_APP_AISOFTWARES_API_ENDPOINT}`
}

export async function verifyRECAPTCHA(token) {
    if (!token) {
        return false;
    }

    return await fetch(`${getApiBaseUrl()}/reCAPTCHA`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: token,
        }),
    });
}

function navigateToPageAndScroll(pageUrl, divId) {
    // Navigate to the page
    window.location.href = pageUrl;

    // Wait for the page to load
    window.onload = () => {
        // Scroll to the specific div
        const element = document.getElementById(divId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
}
// Usage: navigateToPageAndScroll('https://www.example.com/page', 'targetDivId');