import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


// Import your page components
import HomePage from './pages/HomePage';
import {AppProvider} from "./context/AppContext";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ServicesPage from "./pages/ServicesPage";
import ContactPage from "./pages/ContactPage";
// import AboutPage from './pages/AboutPage';
// import ServicesPage from './pages/ServicesPage';
// import CustomGPTDevelopmentPage from './pages/CustomGPTDevelopmentPage';
// import SolutionsPage from './pages/SolutionsPage';
// import MarketplacesPage from './pages/MarketplacesPage';
// import ContactPage from './pages/ContactPage';





function App() {

    const openContactUsModal = () => {
        setContactModalOpen(true);
    };

    const closeContactUsModal = () => {
        setContactModalOpen(false);
    };
    const [isContactModalOpen, setContactModalOpen] = useState(false);

  return (
      <AppProvider>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout><HomePage /></Layout>}/>
                    <Route path="/terms" element={<Layout><TermsOfUse /></Layout>} />
                    <Route path="/terms-of-use" element={<Layout><TermsOfUse /></Layout>} />
                    <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
                    <Route path="/services" element={<Layout><ServicesPage /></Layout>} />
                    <Route path="/contact" element={<Layout><ContactPage /></Layout>} />
                    {/*<Route path="/custom-gpt-development" element={<CustomGPTDevelopmentPage />} />*/}
                    {/*<Route path="/solutions" element={<SolutionsPage />} />*/}
                    {/*<Route path="/marketplaces" element={<MarketplacesPage />} />*/}

                    {/* Add additional routes as needed */}
                </Routes>
            </Router>

        </GoogleReCaptchaProvider>
      </AppProvider>
  );
}

export default App;
