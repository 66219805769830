import React, {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactUsModal from "../components/ContactUsModal";
import {useAppContext} from "../context/AppContext";

const Layout = ({ children }) => {

    const { isContactModalOpen, closeContactUsModal } = useAppContext(); // Use the hook


    return (
        <>
          <Header />
          <main>{children}</main>
            <ContactUsModal isOpen={isContactModalOpen} onClose={closeContactUsModal} />
          <Footer />
        </>
    );
};

export default Layout;
