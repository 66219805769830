import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImg from '../images/hero-section-bg-3.jpg';
import {useAppContext} from "../context/AppContext"; // Replace with the path to your background image

const HomeCallToActionSection = () => {
    const { openContactUsModal } = useAppContext(); // Use the hook

    return (
        <section
            className="text-white text-center py-24"
            style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover' }}
        >
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold mb-4">Transform Your Business with AI</h2>
                <p className="mb-8">Discover the Power of Custom GPT Models and AI Solutions</p>
                <div className="inline-block">
                    <a href="/services"
                       className="m-2 inline-block ml-4 bg-transparent border border-white text-white font-bold py-2 px-4 rounded hover:bg-white hover:text-black transition duration-300">
                        View Our Services
                    </a>
                    <button onClick={openContactUsModal}
                            className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white py-2 px-6 mr-4 rounded">Contact Us
                    </button>
                    {/*<Link to="/learn-more" className="bg-transparent border border-white hover:bg-white hover:text-black py-2 px-6 rounded">Learn More</Link>*/}
                </div>
            </div>
        </section>
    );
};

export default HomeCallToActionSection;
