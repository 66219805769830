import React from 'react';
import { FaStar } from 'react-icons/fa';
import profilePlaceholder1 from '../images/williamkeizer.png'; // Replace with actual profile image path
import profilePlaceholder2 from '../images/joannaesperanza.png'; // Replace with actual profile image path
import companyLogoPlaceholder1 from '../images/cloudsagelogo.jpg'; // Replace with actual company logo path
import companyLogoPlaceholder2 from '../images/datanotationlogo.jpg'; // Replace with actual company logo path

const testimonials = [
    {
        quote: "Our AI-powered GPT model has revolutionized our customer support process. It has significantly reduced response times and improved customer satisfaction.",
        name: "William Keiser",
        title: "Technical Architect",
        profilePicture: profilePlaceholder1,
        companyLogo: companyLogoPlaceholder1,
        rating: 5
    },
    {
        quote: "AISN's custom GPT model has transformed our content creation process. It has enabled us to generate high-quality articles in a fraction of the time.",
        name: "Joanna Esperanza",
        title: "Content Manager",
        profilePicture: profilePlaceholder2,
        companyLogo: companyLogoPlaceholder2,
        rating: 5
    },
    // ...more testimonials
];

const TestimonialSection = () => {
    return (
        <section className="bg-white py-12">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">Customer Stories</h2>
                <p className="text-center mb-12">Hear from our satisfied clients about the effectiveness of AISN's custom GPT solutions.</p>
                <div className="grid md:grid-cols-2 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="flex flex-col items-center md:items-start">
                            <div className="flex justify-center md:justify-start mb-4 md:mb-0 md:mr-8 pt-4 pb-4">
                                {[...Array(testimonial.rating)].map((_, i) => (
                                    <FaStar key={i} className="text-yellow-500"/>
                                ))}
                            </div>
                            <div className="mb-4">
                                <span className="text-lg leading-tight font-medium">{testimonial.quote}</span>
                            </div>
                            <div className="flex items-center ">
                                <div className="flex items-center justify-center space-x-4 p-4">
                                    <img
                                        src={testimonial.profilePicture}
                                        alt={testimonial.name}
                                        className="rounded-full h-12 w-12"
                                    />
                                    <div className="flex flex-col justify-center">
                                        <p className="text-md font-bold">{testimonial.name}</p>
                                        <p className="text-sm">{testimonial.title}</p>
                                    </div>
                                    <img
                                        src={testimonial.companyLogo}
                                        alt="Company Logo"
                                        className="h-8 self-center"
                                    />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;
