import React from 'react';
import { Link } from 'react-router-dom';
import iconPlaceholder1 from '../images/hiw-icon-1.png'; // Replace with actual icon paths
import iconPlaceholder2 from '../images/hiw-icon-2.png'; // Replace with actual icon paths
import iconPlaceholder3 from '../images/hiw-icon-3.png';
import {useAppContext} from "../context/AppContext"; // Replace with actual icon paths

const howItWorksData = [
    {
        title: 'Creating and Training Custom GPT Models',
        description: 'Creating and integrating custom GPT models is a seamless process that leverages advanced artificial intelligence to develop bespoke solutions tailored to your specific business needs.',
        icon: iconPlaceholder1,
        callToAction: "Let's Get Started",
    },
    {
        title: 'Streamlining AI Integration into Your Systems',
        description: 'Our team of experts excels in integrating custom GPT models into your applications, websites, and systems, enhancing functionality and user experience.',
        icon: iconPlaceholder2,
        callToAction: "Contact Us"
    },
    {
        title: 'Help you build your Business',
        description: 'We offer comprehensive management and maintenance services for your custom GPT models, ensuring they remain at the forefront of AI technology and helping our customers grow and be successful on their AI journey.',
        icon: iconPlaceholder3,
        callToAction: "Sign Up",
    },
    // ... Add more sections if needed
];

const HowItWorksSection = () => {
    const { openContactUsModal } = useAppContext(); // Use the hook

    return (
        <section className="bg-white py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center text-center md:text-left">
                    {howItWorksData.map((item, index) => (
                        <div key={index} className="md:flex-1 px-4 mb-8 text-center">
                            <img src={item.icon} alt="" className="mx-auto h-20 w-20 mb-4"/>
                            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                            <p className="mb-4">{item.description}</p>
                            <div className="flex justify-center">


                            </div>
                        </div>
                    ))}
                </div>
                <section className={'flex flex-wrap justify-center text-center'}>
                    <div className="inline-block">
                        <Link onClick={openContactUsModal} to="#"
                              className="m-2 inline-block ml-4 bg-black border border-black text-white hover:text-black font-bold py-2 px-4 rounded hover:bg-white hover:text-black transition duration-300">
                            Contact Us
                        </Link>
                        <a href="/services"
                              className="m-2 inline-block ml-4 bg-transparent border border-black hover:border-white text-black hover:text-white font-bold py-2 px-4 rounded hover:bg-black hover:text-white transition duration-300">
                            View Our Services
                        </a>
                    </div>
                </section>

            </div>
        </section>
    );
};

export default HowItWorksSection;
