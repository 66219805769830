import React from 'react';
import HeroSection from './../components/HeroSection';
import FeaturesSection from "../components/FeaturesSection";
import FeaturesListSection from "../components/FeaturesListSection";
import HowItWorksSection from "../components/HowItWorksSection";
import StatisticsSection from "../components/StatisticsSection";
import TestimonialSection from "../components/TestimonialSection";
import HomeCallToActionSection from "../components/HomeCallToActionSection";
import SubscribeToNewsletterSection from "../components/SubscribeToNewsletterSection";

const HomePage = () => {
    return (
        <>
            {/* Hero section should not be inside the container */}
            <HeroSection />

            <FeaturesSection />

            <FeaturesListSection />

            <HowItWorksSection />

            <StatisticsSection />

            <TestimonialSection />

            <HomeCallToActionSection />

            <SubscribeToNewsletterSection />

        </>
    );
};

export default HomePage;
