import React from 'react';
import placeholderImage from '../images/hero-section-bg-1.jpg';
import {scrollToNextSection} from "../functions"; // Make sure this path is correct

const HeroSection = () => {
  return (
    <div className="relative bg-black text-white min-h-screen flex items-center">
      {/* Background image */}
      <img src={placeholderImage} className="absolute inset-0 object-cover w-full h-full" alt="background" />

      {/* Overlay */}
      <div className="bg-black bg-opacity-50 absolute inset-0" />

      {/* Content */}
      <div className="relative px-4 sm:px-6 lg:px-40 w-full">
        <div className="max-w-xl"> {/* Adjust this value based on your design */}
          <h1 className="text-6xl font-bold leading-tight mb-6">
            Enhance the Power of AI Technology
          </h1>
          <p className="mb-8 text-xl">
            AI Softwares specializes in creating custom GPT models for advanced AI solutions.
            Our expertise in developing and managing these models allows us to provide tailored solutions to meet your
            specific business needs.
          </p>
          <div className="flex gap-4 items-center">
            <button
                onClick={scrollToNextSection}
                className="bg-black hover:bg-white hover:text-black border border-white px-6 py-2
                rounded-md text-lg font-medium transition duration-300"
            >
              Learn More
            </button>
            <a
                href="/contact"
                className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-md text-lg font-medium transition duration-300 flex items-center justify-center"
                style={{minWidth: "120px"}} // Set a minimum width to make both buttons equal width
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
