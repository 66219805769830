import React from 'react';
import {Link} from "react-router-dom";

const year = new Date().getFullYear();

const Footer = () => {
  return (
      <>

          <footer className="bg-black text-white py-4 px-8 text-center md:flex md:flex-row align-middle justify-between">
              <div className={'md:flex md:flex-row p-1 m-1 space-x-3 align-middle'}>
                  <Link to="/terms-of-use">Terms of Use</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className={'md:flex md:flex-row p-1 m-1 space-x-3 align-middle'}>
                  <p>© {year} AI Softwares Network (AISN). All rights reserved.</p>
              </div>
              {/* Add footer content here */}
          </footer>
      </>
  );
};

export default Footer;
