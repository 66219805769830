import React from 'react';
import {scrollToNextSection} from "../functions";
import backgroundImage from '../images/hero-section-bg-4.jpg';
import featuresImage1 from '../images/Bespoke GPT Models.png';
import featuresImage2 from '../images/Software Development using AI.png';
import featuresImage3 from '../images/Comprehensive Management.png';
import featuresImage4 from '../images/Transform Business Processes.png';
import featuresImage5 from '../images/Flexible Pricing Plans.png';
import featuresImage6 from '../images/Custom AI Solutions.png';
import icon01 from '../images/Proactive Support.png';
import icon02 from '../images/Lifetime Realliability.png';
import icon03 from '../images/Prompt Engineering.png';
import icon04 from '../images/Sell Digital Products.png';

import {Link} from "react-router-dom";

const ServicesPage = () => {

    const features = [
        {
            id: 1,
            title: 'Bespoke GPT Models',
            description: 'Custom GPT solutions tailored to your business needs. This includes consultation and requirements gathering',
            icon: featuresImage1
        },
        {
            id: 2,
            title: 'Software Development using AI',
            description: 'Programming, configuration and training of AI models to deliver custom software solutions. This includes any digital product that requires AI capabilities.',
            icon: featuresImage2
        },
        {
            id: 3,
            title: 'Comprehensive Management',
            description: 'Full lifecycle management to keep your AI models at peak performance. This includes regular updates and optimizations based on Service Agreement.',
            icon: featuresImage3
        },
        // ...add more features as needed
    ];

    const integrations = [
        {
            id: 1,
            title: 'Transform Business Processes',
            description: 'Elevate your operational efficiency with our specialized services designed to transform and automate business processes. By leveraging advanced GPT models, we provide solutions that enhance productivity, streamline workflows, and drive innovation. Our approach is focused on delivering tailor-made, AI-powered strategies that align with your unique business objectives, ensuring a seamless integration for an improved user experience.',
            icon: featuresImage4
        },
        {
            id: 2,
            title: 'Flexible Pricing Plans',
            description: "Embrace the power of AI with our cost-effective pricing strategies. At AI Softwares, we understand that each client has unique budgetary needs. That\'s why we offer flexible pricing plans tailored to the complexity and scope of your project. Whether you\'re looking for installment options or seeking pricing discounts, our goal is to provide high-quality AI solutions that align with your financial requirements. Experience the benefits of AI with a pricing model that adapts to your business's evolving needs and budget.",
            icon: featuresImage5
        },
        {
            id: 3,
            title: 'Custom AI Solutions',
            description: 'Tailored AI solutions that fit your business requirements perfectly. It could be anything from just simple AI images / text generations, chatbots, prototypes or digital products to a full-fledged AI-powered software or support your business needs using AI. We will work with you to understand your needs and deliver a solution that is right for you.',
            icon: featuresImage6
        },
        // ...add more integrations as needed
    ];


    const managementFeatures = [
        {
            id: 1,
            title: 'Proactive Support',
            description: 'Regular updates and optimizations to keep your AI models at the cutting edge.',
            icon: icon01
        },
        {
            id: 2,
            title: 'Lifetime Reliability',
            description: 'We offer lifetime support for all AI models, ensuring long-term performance.',
            icon: icon02
        },
        // ...add more management features as needed
    ];

    const engineeringFeatures = [
        {
            id: 1,
            title: 'Prompt Engineering',
            description: 'Crafting unique solutions by efficiently leveraging AI technologies for prompt engineering.',
            icon: icon03
        },
        {
            id: 2,
            title: 'Sell Digital Products',
            description: 'We specialize in helping businesses create and sell digital products for their business',
            icon: icon04
        },
        // ...add more engineering features as needed
    ];

    return (
        <>
            <section className="hero-section"
                     style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                {/* Your background image would be set here via CSS or inline styles */}
                <div className="container mx-auto text-center p-12">
                    <h1 className="text-5xl font-bold text-white mb-6 shadow-black">Services</h1>
                    <p className="text-xl text-white mb-8 shadow-black">
                        Unlocking the Power of AI to Drive Business Success
                    </p>
                    <div>
                        {/*<button  onClick={scrollToNextSection}  className="btn-primary">Learn More</button>*/}
                        <Link to={'/contact'}><button  className="btn-secondary">Contact Us</button></Link>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {features.map((feature) => (
                            <div key={feature.id}
                                 className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                                <img src={feature.icon} alt={feature.title} className="mb-4 h-12 w-12"/>
                                <h3 className="mb-2 font-semibold text-lg text-gray-900">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Integration Section */}
            <section className="py-12 bg-white">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {integrations.map((integration) => (
                            <div key={integration.id}
                                 className="flex flex-col items-center text-center p-6 bg-gray-100 rounded-lg shadow-md">
                                <img src={integration.icon} alt={integration.title} className="mb-4 h-12 w-12"/>
                                <h3 className="mb-2 font-semibold text-lg text-gray-900">{integration.title}</h3>
                                <p className="text-gray-600">{integration.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Management Section */}
            <section className="py-12 bg-gray-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-center text-3xl font-bold mb-12">Comprehensive Management and Maintenance</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {managementFeatures.map((feature) => (
                            <div key={feature.id}
                                 className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                                <img src={feature.icon} alt={feature.title} className="mb-4 h-12 w-12"/>
                                <h3 className="mb-2 font-semibold text-lg text-gray-900">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Engineering Section */}
            <section className="py-12 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-center text-3xl font-bold mb-12">Unlocking the Power of Generative AI: Expertise
                        in Prompt Engineering</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {engineeringFeatures.map((feature) => (
                            <div key={feature.id}
                                 className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-lg shadow-md">
                                <img src={feature.icon} alt={feature.title} className="mb-4 h-12 w-12"/>
                                <h3 className="mb-2 font-semibold text-lg text-gray-900">{feature.title}</h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-center text-3xl font-bold mb-6">Rapid Deployment: Delivering Custom GPT Models Quickly</h2>
                    <p className="text-center text-lg mb-8">
                        At AI Softwares, we understand the importance of speed and efficiency when it comes to delivering custom
                        GPT models. Our rapid deployment process ensures that you receive your tailored solution in the
                        shortest possible time, without compromising on quality. With our expertise in developing and
                        training GPT models, we can deliver results within a day, depending on your specific needs.
                        Trust AI Softwares to accelerate your AI journey.
                    </p>
                    <div className="text-center">
                        <a href="/contact"
                           className="inline-block bg-black hover:bg-white text-white hover:text-black font-bold py-2 px-4 rounded hover:bg-opacity-90  transition duration-300">
                            Contact Us
                        </a>
                    </div>
                </div>
            </section>


        </>
    );
};

export default ServicesPage;
