import React, { useState } from 'react';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getApiBaseUrl, verifyRECAPTCHA } from "../functions";

const SubscribeToNewsletterSection = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlertMessage('');
        setAlertSeverity('');

        if (!email) {
            setAlertMessage('Please enter your email address.');
            setAlertSeverity('error');
            setLoading(false);
            return;
        }

        try {
            const token = await executeRecaptcha('subscribe_newsletter');
            const reCAPTCHAResult = await verifyRECAPTCHA(token);
            if (!reCAPTCHAResult) {
                setAlertMessage('Failed to verify reCAPTCHA. Please try again.');
                setAlertSeverity('error');
                return;
            }

            const response = await fetch(`${getApiBaseUrl()}/subscribe/newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setAlertMessage('You have successfully subscribed to our newsletter!');
                setAlertSeverity('success');
            } else {
                setAlertMessage('Failed to subscribe to our newsletter.');
                setAlertSeverity('error');
            }
        } catch (error) {
            setAlertMessage('An error occurred. Please try again later.');
            setAlertSeverity('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="bg-white py-12" id={'home-newsletter'}>
            <div className="container mx-auto px-4 md:flex justify-center">
                <div className={'p-4 pr-12'}>
                    <h2 className="text-3xl font-bold">Get the Latest AI Insights</h2>
                    <p className="mt-2">Stay updated with industry news and AI advancements</p>
                </div>
                <div className={'p-4 pr-12'}>
                    {alertMessage && (
                        <div className={`alert alert-${alertSeverity}`}>
                            {alertMessage}
                        </div>
                    )}
                    <form onSubmit={handleSubscribe} className="md:flex">
                        <input
                            type="email"
                            placeholder="Enter email address"
                            className="px-4 py-2 border border-gray-300 rounded-l h-12 w-80 focus:outline-none focus:ring-2 focus:ring-gray-200"
                            required
                            value={email}
                            onChange={handleEmailChange}
                            disabled={loading}
                        />
                        <button
                            type="submit"
                            className="bg-black text-white rounded-r hover:bg-opacity-90 transition duration-300 md:h-12 w-30"
                            disabled={loading}
                        >
                            {loading ? 'Subscribing...' : 'Subscribe'}
                        </button>
                    </form>
                    <p className="text-xs text-gray-500 pt-3">
                        By subscribing, you agree to our Terms and Conditions
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SubscribeToNewsletterSection;
