import React from 'react';

const StatisticsSection = () => {
    return (
        <div className="bg-gray-100 py-16 px-8">
            <div className="container mx-auto pt-12 pb-12">
                <div
                    className="flex flex-col md:flex-row items-center justify-center md:justify-between text-center md:text-left">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h2 className="text-3xl font-bold mb-4">
                            Transforming Businesses with Advanced AI Solutions and Custom GPT Models
                        </h2>
                        <p>
                            Discover the power of AI Softwares and our expertise in developing and managing
                            custom
                            Generative Pre-trained Transformer (GPT) models. With our advanced artificial intelligence
                            solutions,
                            we help businesses achieve remarkable growth and success.
                        </p>
                    </div>
                    <div className="md:w-1/2 flex flex-row justify-center md:justify-end space-x-8">
                        <div className="text-center">
                            <span className="text-5xl font-bold">58%</span>
                            <p className="text-xl">increase in customer satisfaction through AI-driven solutions</p>
                        </div>
                        <div className="text-center">
                            <span className="text-5xl font-bold">62%</span>
                            <p className="text-xl">reduction in operational costs with our cutting-edge AI
                                technology</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatisticsSection;
