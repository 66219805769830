// src/contexts/AppContext.js
import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export function useAppContext() {
    return useContext(AppContext);
}

export function AppProvider({ children }) {
    const [isContactModalOpen, setContactModalOpen] = useState(false);

    const openContactUsModal = () => setContactModalOpen(true);
    const closeContactUsModal = () => setContactModalOpen(false);

    return (
        <AppContext.Provider value={{ isContactModalOpen, openContactUsModal, closeContactUsModal }}>
            {children}
        </AppContext.Provider>
    );
}
