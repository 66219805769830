import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>
            <p className="mb-4 float-end text-sm">Last Updated: January 2024</p>

            <h2 className="text-2xl font-semibold mb-3">1. What We Collect</h2>
            <p className="mb-4">
                Our information collection process is comprehensive, encompassing various forms of data to enhance your experience. This includes your personal details such as name, email address, and contact info, particularly collected when you engage with our services like newsletter subscriptions or direct inquiries.
                <br /><br />
                We also gather information from third-party sources, which, when combined with the data we have, helps us understand your needs better.
                <br /><br />
                Our website automatically collects specific data, including your device type, browser, browsing behavior, and interaction patterns. This helps us tailor our services and content to your preferences.
                <br /><br />
                Cookies, which are small data files stored on your device, are used to remember your preferences and settings, making your repeated visits more personalized and efficient.
            </p>

            <h2 className="text-2xl font-semibold mb-3">2. Use of Personal Information</h2>
            <p className="mb-4">
                The personal information we collect is primarily used to provide, maintain, and improve our services. We are committed to enhancing your experience on our platform by personalizing content and offerings.
                <br /><br />
                Your data enables us to respond effectively to your queries and provide top-notch customer service. It also allows us to communicate essential updates, security alerts, and administrative messages.
                <br /><br />
                Furthermore, we use your information to keep you informed about our new products, services, and upcoming events, ensuring you are always up-to-date with our latest developments.
            </p>

            <h2 className="text-2xl font-semibold mb-3">3. Sharing of Personal Information</h2>
            <p className="mb-4">
                We value your privacy and only share your personal information under specific circumstances. With your explicit consent, we may share your data with partners for marketing purposes, always adhering to their privacy policies.
                <br /><br />
                In business transactions such as mergers or acquisitions, personal information may be transferred as part of our business assets. We also share data for legal purposes, including compliance with laws and protecting our rights and the safety of our users.
            </p>

            <h2 className="text-2xl font-semibold mb-3">4. Information Choices and Changes</h2>
            <p className="mb-4">
                You have complete control over your personal information. Our marketing communications provide clear instructions on how to opt-out of receiving future messages.
                <br /><br />
                You can also contact us to update your personal information, change contact preferences, or request not to share your data with third parties.
            </p>

            <h2 className="text-2xl font-semibold mb-3">5. Contact Us</h2>
            <p className="mb-4">
                Should
                you have any questions or concerns regarding this Privacy Policy, or wish to exercise your rights related to your personal data, please feel free to reach out to us. We are committed to addressing your queries promptly and ensuring your data protection rights are fully respected.
                <br /><br />
                Contact us at contact@aisoftwares.net for detailed inquiries, feedback, or any data-related requests. Our team is dedicated to providing clarity and assistance regarding our data practices and your privacy.
            </p>
            <h2 className="text-2xl font-semibold mb-3">6. Changes to This Privacy Policy</h2>
            <p className="mb-4">
                We reserve the right to update this Privacy Policy to reflect changes in our data practices. Such modifications could be due to new services, changes in technology, or legal requirements.
                <br /><br />
                We encourage you to review this policy periodically to stay informed about how we protect your information and rights. Any significant changes will be communicated through our website or direct notifications, as appropriate.
            </p>
        </div>
    );
};

export default PrivacyPolicy;