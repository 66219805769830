import React from 'react';
import placeholderImage from '../images/home_feature_image-1.jpg';
import {scrollToNextSection} from "../functions";
import {useAppContext} from "../context/AppContext"; // Replace with the path to your actual image

const FeaturesSection = () => {
    const { openContactUsModal } = useAppContext(); // Use the hook

    return (
        <div className="bg-gray-100 py-16 px-8">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                <div className="md:w-1/2">
                    <h2 className="text-4xl font-semibold mb-4">Custom GPT Models: Tailored Solutions for Businesses</h2>
                    <p className="mb-8">
                        At AI Softwares, we specialize in creating and managing custom GPT models that are specifically designed to meet the unique needs of businesses. Our advanced artificial intelligence technology allows us to develop bespoke solutions that enhance functionality and user experience.
                    </p>
                    <div className="mb-8">
                        <h3 className="text-2xl font-semibold">Tailored Solutions</h3>
                        <p>Unlock the Power of AI with Custom GPT Models</p>
                    </div>
                    <div className="mb-8">
                        <h3 className="text-2xl font-semibold">Enhanced Functionality</h3>
                        <p>Integrate Custom GPT Models to Optimize Your Applications and Systems</p>
                    </div>
                    <div>
                        <button onClick={scrollToNextSection} className="bg-transparent hover:bg-black border-solid border-black hover:border-white text-black hover:text-white py-2 px-4 mr-4 rounded">Learn More</button>
                        <button onClick={openContactUsModal} className="bg-black hover:bg-white border-solid border-black hover:border-black py-2 px-4 rounded hover:text-black ">Contact Us</button>
                    </div>
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end mt-8 md:mt-0 md:p-8">
                    {/* Placeholder for image or icon */}
                    <img src={placeholderImage} alt="Feature" />
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection;
