import React, {useEffect, useState} from 'react';
import Loader from "../components/Loader";
import backgroundImage from "../images/hero-section-bg-4.jpg";

const ContactPage= () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [status, setStatus] = useState({
        submitted: false,
        submitting: false,
        error: false,
        message: ''
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setStatus({ ...status, submitting: true });
        // Check if any field is empty
        if (!formData.name || !formData.email || !formData.message) {
            alert('Please fill in all the fields.');
            return;
        }

        // Here, you would implement the API call to Zapier webhook
        // which then triggers the email action.
        try {
            const response = await fetch(`${process.env.REACT_APP_ZAPIER_WEBHOOK_URL}`, {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response) {
                setStatus({
                    submitted: true,
                    submitting: false,
                    error: false,
                    message: 'Thank you! Your message has been sent. We will get back to you soon.'
                });
                setFormData({ name: '', email: '', message: '' }); // Clear the form
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            setStatus({
                submitted: false,
                submitting: false,
                error: true,
                message: 'There was a problem submitting your message. Please try again.'
            });
        }
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxSizing: 'border-box',
    };

    const buttonStyle = {
        backgroundColor: '#000',
        color: '#FFF',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    };

    return (
        <>
            <section className="hero-section"
                     style={{
                         backgroundImage: `url(${backgroundImage})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         height: '15vh',
                     }}
            >
                <div className="container mx-auto text-center p-12">
                    <h1 className="text-5xl font-bold text-white mb-6">Contact Us</h1>
                </div>
            </section>
            <section className="py-12">
            <div className="container mx-auto px-4 w-1/2">
                    <h2 className="text-4xl font-bold text-gray-900 my-4">We'd like to hear from you</h2>
                    <div className="text-lg text-gray-700 mb-4">
                        <div className="bg-blue-100 border-l-4 border-blue-100 text-black p-4 rounded-xl" role="alert">
                            <p className="font-bold">
                                <span className="float-end"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                                 className="w-8 h-8 inline-block mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M13 16h-1v-4h1m0-4h-1v1h1v3m6 8v-1.5a2 2 0 00-2-2h-1a2 2 0 00-2 2v1.5m6 0H7"></path>
                                </svg>
                                </span>
                                Stay Updated
                            </p>
                            <p>At AI Softwares, we understand that every client has unique requirements. Our team is
                                ready to answer your queries and provide you with the customized AI solutions you need.
                                Reach out to us with your objectives or ask any questions regarding our services. We aim
                                to respond within one business day, and our pricing is tailored to fit the scope of your
                                project.</p>
                            <br />
                            <p>For more information on a wide range of services AI Softwares has to offer, visit our <a href={'/services'}><span className={'font-bold'}>Services</span></a> page and stay connected with us.</p>
                        </div>
                    </div>
                    {status.submitted ? (
                        <div
                            className="confirmation-message flex flex-col items-center justify-center p-4 rounded-lg bg-green-100">
                            <i className="fas fa-check-circle text-green-500 text-5xl"></i>
                            <p className="text-green-800 font-semibold mt-3">{status.message}</p>
                        </div>
                    ) : (
                        <form onSubmit={handleFormSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                style={inputStyle}
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                style={inputStyle}
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <textarea
                                name="message"
                                placeholder="Type your message..."
                                style={{...inputStyle, height: '150px'}}
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            {status.submitting ? <Loader/> : (
                                <button type="submit" style={buttonStyle}>Submit</button>)}
                        </form>
                    )}
                </div>
            </section>

        </>
    );
};


export default ContactPage;
