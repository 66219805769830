import React from 'react';
import placeholderImage from '../images/home_feature_image-2.jpg'; // Replace with the path to your actual image

const features = [
    {
        title: "Bespoke GPT Model Development",
        description: "We specialize in creating custom GPT models tailored to your specific business needs. Our team utilizes advanced artificial intelligence techniques to develop unique solutions that leverage both proprietary and client-specific data."
    },
    {
        title: "Prompt Engineering",
        description: "By leveraging our extensive expertise in Prompt Engineering, we ensure effective communication and generate the best results. This allows us to enhance functionality and improve the overall user experience of any Generative AI system, ultimately leading to more seamless interaction and greater satisfaction for users."
    },
    {
        title: "Lifetime Support",
        description: "We provide comprehensive management and maintenance services for all GPT models created. Our commitment includes regular updates and optimizations to ensure your models remain at the forefront of AI technology."
    },
    // Add more features as needed
];

const FeaturesListSection = () => {
    return (
        <section className="py-12">
            <div className="container mx-auto px-4 flex flex-wrap items-center justify-center" style={{ minHeight: '50vh' }}>
                <div className="w-full lg:w-1/2 flex justify-center lg:justify-end md:p-8">
                    <img src={placeholderImage} alt="Feature visual" className="mb-4 lg:mb-0" />
                </div>
                <div className="w-full lg:w-1/2 flex justify-center lg:justify-start">
                    <div className="text-center lg:text-left">
                        {features.map((feature, index) => (
                            <div key={index} className="mb-8">
                                <h3 className="text-2xl font-semibold mb-3">{feature.title}</h3>
                                <p>{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesListSection;
