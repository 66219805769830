import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-6">Terms of Use</h1>
            <p className="mb-4 float-end text-sm">Last Updated: January 2024</p>

            <h2 className="text-2xl font-semibold mb-3">1. Use of the Service</h2>
            <p className="mb-4">
                By accessing or using the Service you agree to be bound by these Terms.
                If you disagree with any part of the terms, then you may not access the Service.
            </p>

            <h2 className="text-2xl font-semibold mb-3">2. Intellectual Property</h2>
            <p className="mb-4">
                The Service and its original content, features, functionality, and proprietary GPT models developed by
                AI Softwares are the exclusive property of AI Softwares and its licensors, except as expressly agreed
                upon in writing. In cases where AI Softwares has agreed to develop custom AI applications or models
                using client data, the resulting intellectual property, including the trained AI models and associated
                outputs, shall belong to the client, subject to the terms of the specific agreement between the client
                and AI Softwares.

                Clients retain ownership of their data, and any intellectual property rights associated with the data
                they provide for the purpose of creating and training AI models. AI Softwares acknowledges that any use
                of client data in the development of AI applications or models is done so under the explicit instruction
                of the client and will not claim ownership over the data or the intellectual property inherently
                belonging to the client as a result of such data.

                All such agreements will be documented in a separate written agreement, typically referred to as a
                "Service Agreement," "Development Agreement," or "Intellectual Property Assignment," which will specify
                the ownership and licensing of the intellectual property created as a result of the services rendered by
                AI Softwares.
            </p>

            <h2 className="text-2xl font-semibold mb-3">3. Services</h2>
            <p className="mb-4">
                AI Softwares offers specialized services in developing custom GPT models and AI software solutions
                tailored to the unique needs of our clients. Our services are designed to align closely with the
                specific requirements and objectives of each client, ensuring that the resulting AI technologies provide
                maximum value and performance. By engaging with AI Softwares for such services, clients agree to
                collaborate by providing necessary information, feedback, and data to facilitate the development
                process. AI Softwares commits to maintaining the confidentiality and integrity of the client's data and
                intellectual property throughout the service engagement.
            </p>

            <h2 className="text-2xl font-semibold mb-3">4. Termination</h2>
            <p className="mb-4">
                We may terminate or suspend access to our Service immediately, without prior notice or liability, for
                any reason whatsoever, including, without limitation, if you breach the Terms.

                All provisions of the Terms which by their nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
                liability.
            </p>

            <h2 className="text-2xl font-semibold mb-3">5. Products on Third-Party Marketplaces</h2>
            <p className="mb-4">
                AI Softwares develops and offers a range of digital products, including applications, assets, plugins
                and extensions
                available for purchase on various third-party marketplaces. These products are subject to the terms and
                conditions of the respective marketplace as well as our own policies. The purchase and use of our
                products are governed by a separate set of terms that provide for the licensing, use restrictions, and
                other relevant considerations specific to each product. When purchasing or using our products from these
                marketplaces, customers are also bound by the terms of service and privacy policies of the marketplace
                platform. AI Softwares is not responsible for any transactions conducted outside of our official
                channels, and we encourage customers to carefully review the marketplace policies before making a
                purchase.
            </p>

            <h2 className="text-2xl font-semibold mb-3">6. Governing Law</h2>
            <p className="mb-4">
                These Terms shall be governed and construed in accordance with the laws of Australia, without regard to
                its conflict of law provisions.

                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect.
            </p>

            <h2 className="text-2xl font-semibold mb-3">7. Changes</h2>
            <p className="mb-4">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                revision is material, we will try to provide at least 3 days' notice prior to any new terms taking
                effect. What constitutes a material change will be determined at our sole discretion.
            </p>


            <h2 className="text-2xl font-semibold mb-3">8. Contact Us</h2>
            <p className="mb-4">
                If you have any questions about these Terms, please contact us at contact@aisoftwares.net.
            </p>
        </div>
    );
};

export default TermsOfUse;
